<template>
  <div v-if="user">
    <div class="w-full text-center">
      <img crossorigin="anonymous" :src="pictureURL" class="inline object-cover rounded-full" :class="{
        'w-2/4 h-auto mb-4': avatarSize === 'big',
        'w-20 h-20': avatarSize === 'medium'
      }" />
    </div>
    <div class="w-full text-center mt-2 flex flex-col">
      <span class="font-bold text-black">{{ user.name }}</span>
      <span class="text-black" v-if="showEmail">{{ user.email }}</span>
      <span class="italic text-black my-8" v-if="showMemberSince">Medlem siden {{ user.createdAt }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  props: {
    avatarSize: {
      type: String,
      required: false,
      default: 'big'
    },
    showAvatar: {
      type: Boolean,
      required: false,
      default: true
    },
    showName: {
      type: Boolean,
      required: false,
      default: true
    },
    showEmail: {
      type: Boolean,
      required: false,
      default: true
    },
    showMemberSince: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    user () {
      return this.$store.state.user ?? null
    },
    pictureURL () {
      if (!this.$store.state.offline && this.user && this.user.image && this.user.image.length > 0) {
        return this.user.image
      }

      return '/img/default_profile.png'
    }
  }
}
</script>

<style scoped>

</style>
