<template>
  <div>
    Logger ind..
  </div>
</template>

<script>
export default {
  name: 'AuthCallback',
  props: {
    provider: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
