import { render, staticRenderFns } from "./CloseNavigationItem.vue?vue&type=template&id=5e616c9c&scoped=true&"
import script from "./CloseNavigationItem.vue?vue&type=script&lang=js&"
export * from "./CloseNavigationItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e616c9c",
  null
  
)

export default component.exports