<template>
  <div>
    <nav-bar>
      <template slot="left">
        <back-navigation-item @trigger="popView()" />
      </template>
    </nav-bar>
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display font-bold uppercase text-green text-center">Cookie- og privatlivspolitik</h1>
      </div>
      <div class="mt-8">

        <p><span style="font-size: 1rem;">Tarup Center gør brug af tredjepartscookies når du besøger vores hjemmeside for at forbedre brugeroplevelsen på vores hjemmeside, samt til at indsamle relevant statistik om besøgende.</span></p>
        <br>
        <p><strong>Oplysninger der kan indsamles, indebærer blandt andet:</strong></p>
        <ul class="list-styling">
          <li>IP-adresser og navn på internetudbyder</li>
          <li>Browser-version (herunder styresystem)</li>
          <li>Hjemmesiden som henviste besøgende til tarupcenter.dk</li>
          <li>Tidspunkt for besøget</li>
          <li>Sider besøgt mens der navigeres på hjemmesiden</li>
        </ul>
        <p>Statistik indsamles anonymt.</p>
        <br>
        <p><strong>Tredjepartscookies<br></strong>Vi benytter cookies fra en række udvalgte samarbejdspartnere, nedenfor finder du en liste over alle virksomheder der kan levere tredjepartscookies på Tarup Centers hjemmeside.</p>
        <p>Tryk på virksomheden i listen for at den relevante cookie-politik.</p>
        <ul class="list-styling">
          <li><a href="https://www.facebook.com/policies/cookies/" target="_blank" rel="nofollow noopener">Facebook</a></li>
          <li><a href="https://site.adform.com/privacy-center/adform-cookies/" target="_blank" rel="nofollow noopener">AdForm</a></li>
          <li><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" rel="nofollow noopener">Google Analytics</a></li>
        </ul>
        <p><strong><br>Fjernelse af cookies<br></strong>Det er muligt at fravælge cookies eller slette cookies i en almindelig internetbrowser. Hjemmesidens funktionalitet kan ikke&nbsp;garantere, såfremt cookies er fravalgt i den besøgendes
          internetbrowser.</p>
        <p>Ønsker du at slette eller fjerne cookies, findes en vejledning til dette på&nbsp;<a href="https://www.aboutcookies.org/page/2/" target="_blank" rel="nofollow noopener">aboutcookies.org</a>.</p>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import BackNavigationItem from '@/components/BackNavigationItem.vue'
import popFromStackMixin from '@/mixins/popFromstack'
// import requiresAuthMixin from '../mixins/requiresAuth'
// import {StoreRepostiory} from '@/data/repositories/StoreRepostiory'

export default {
  name: 'CookiePolicy',
  metaInfo: {
    title: 'Cookie- og privatlivspolitik'
  },
  mixins: [
    popFromStackMixin
    // requiresAuthMixin
  ],
  components: {
    BackNavigationItem,
    NavBar
  }
}
</script>
<style>
.list-styling {
  margin: 20px;
  list-style: disc;
}
</style>
