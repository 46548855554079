<template>
  <div>
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display font-bold uppercase text-green text-center">Om Kundeklub</h1>
      </div>
      <div class="mt-8">
        <p>Når du er tilmeldt Tarup Centers nyhedsbrev, vil du være først til at høre om nyheder, events og konkurrencer mm.  Samtidig bliver du medlem af Tarup Centers kundeklub.</p>
        <br>
        <p>Du vil automatisk få tilsendt vores nyhedsmail cirka to gange om måneden – måske lidt oftere, hvis vi har noget spændende, som vi gerne vil dele med dig.</p>
        <p>Vi vil løbende have helt unikke Sommertilbud, som KUN er for kundeklubbens medlemmer.</p>
        <br>
        <p>Du kan naturligvis altid framelde dig igen, men vi håber at du bliver hos os.</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { StoreRepostiory } from '@/data/repositories/StoreRepostiory'

export default {
  name: 'About',
  metaInfo: {
    title: 'Om kuponhæftet'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar
  }
}
</script>
