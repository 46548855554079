<template>
  <div>
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display font-bold uppercase text-green text-center">Favoritter</h1>
      </div>
      <Offline>
        <div class="w-full bg-black p-4 my-3">
          Din enhed er ikke forbundet til internettet, og derfor vil du ikke kunne indløse kuponner.
        </div>
      </Offline>
      <div v-if="this.$store.getters.ready">
        <div v-if="this.coupons.length > 0">
          <Coupon
            v-for="(coupon) in coupons"
            :key="coupon.id"
            :coupon="coupon"
            @show-coupon="showCoupon(coupon)"
          />
        </div>
        <div v-else>
          Du har endnu ikke markeret nogle kuponer som favorit.
        </div>
      </div>
      <div v-else>
        Indlæser..
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Coupon from '@/components/Coupon'
import NavBar from '@/components/NavBar'
import Offline from 'v-offline'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'

export default {
  name: 'CouponFavourites',
  metaInfo: {
    title: 'Favoritter'
  },
  components: {
    BurgerNavigationItem,
    NavBar,
    Coupon,
    Offline
  },
  computed: {
    coupons () {
      return this.$store.state.coupons.filter(function (coupon) {
        return coupon.favourite === true
      }) ?? []
    }
  },
  methods: {
    showCoupon (coupon) {
      this.$router.push({
        name: 'coupons.show',
        params: {
          id: coupon.id
        }
      })
    }
  }
}
</script>
