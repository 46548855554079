<template>
  <div class="h-full bg-green login">
    <div>
      <div class="container mx-auto py-14">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto"/>
      </div>
    </div>
    <div class="bg-white h-full rounded-t-3xl">
      <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
        <div v-if="error" class="text-center text-orange">
          {{ error }}
        </div>
        <div class="text-center">
          Tilmeld dig for at få adgang til gode tilbud og rabatter fra vores butikker
        </div>
        <div v-if="loggingIn">
          <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
        </div>
        <div class="px-4 py-4 flex flex-col" v-if="loggingIn === false">
          <div>
            <form @submit.prevent="handleSubmit">
              <div class="form-group">
                <input type="text" v-model="email" name="email" class="form-control" placeholder="E-mail"
                       :class="{'is-invalid': submitted && !email }"/>
                <div v-show="submitted && (!email || !this.isValidEmail())" class="invalid-feedback">E-mail er ikke
                  gyldig
                </div>
              </div>
              <div class="form-group">
                <input type="text" v-model="name" name="name" class="form-control" placeholder="Navn"
                       :class="{'is-invalid': submitted && !name }"/>
                <div v-show="submitted && !name" class="invalid-feedback">Navn er påkrævet</div>
              </div>
              <!--div class="form-group">
                <input type="password" v-model="password" name="password" placeholder="Adgangskode" class="form-control"
                       :class="{ 'is-invalid': submitted && !password }"/>
                <div v-show="submitted && (!password || !this.isValidPassword())" class="invalid-feedback">Adgangskode skal indeholde mindst otte tegn
                </div>
              </div-->
              <div class="form-group" :class="{ errors: (submitted && !newsletter)}">
                <label for="newsletter" class="checkbox-container">Når du registrerer dig her, vil du automatisk få
                  tilsendt vores gratis nyhedsmail cirka 1-2 gang om måneden. Mailen giver info om masser af gode
                  tilbud, åbningstider, generelle nyheder og invitationer til specielle arrangementer. Du vil også
                  modtage eksklusive TC-VIP tilbud, som kun du, som modtager af nyhedsbrevet, vil få adgang til. Du kan
                  naturligvis altid framelde dig igen, hvis du ønsker det.
                  <input type="checkbox" id="newsletter" v-model="newsletter" name="newsletter">
                  <span class="mark"></span>
                </label>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" :disabled="loggingIn">Tilmeld</button>
                <img v-show="loggingIn"/>
                <div class="links-group">
                  <router-link to="/login" class="btn btn-link">Tilbage til login</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <slide-navigation-item @tapped="selectSlideNavItem('cookie')">
            Cookie- og privatlivspolitik
          </slide-navigation-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SlideNavigationItem from '@/components/SlideNavigationItem'
import { Providers, Facebook, Google } from 'universal-social-auth'
import { APIClient } from '@/data/APIClient'
import { User } from '@/data/models/User'
import { CouponRepository } from '@/data/repositories/CouponRepository'

export default {
  name: 'Register',
  metaInfo: {
    title: 'Tilmeld'
  },
  components: {
    SlideNavigationItem
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      responseData: {},
      hash: '',
      data: {},
      error: null,
      loggingIn: false,
      email: '',
      name: '',
      password: '',
      newsletter: false,
      submitted: false
    }
  },
  methods: {

    selectSlideNavItem (item) {
      this.$store.commit('setShowMenu', false)
      this.$router.push({ name: item })
    },
    isValidEmail () {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email)
    },

    isValidPassword () {
      return this.password.length >= 8
    },
    /**
     * Initiate authentication
     */
    useAuthProvider (provider, proData) {
      const pro = proData
      const ProData = pro || Providers[provider]

      // Authenticate
      this.$Oauth.authenticate(provider, ProData).then((response) => {
        const rsp = response
        this.loggingIn = true

        if (rsp.code) {
          this.responseData.code = rsp.code
          this.responseData.provider = provider
          this.responseData.redirectUri = `${window.location.origin}/auth/${provider}`
          this.useSocialLogin()
        }
      }).catch((err) => {
        this.showLoginError(err)
      })
    },

    /**
     * @param error{string|object} Response data
     */
    async showLoginError (error) {
      this.loggingIn = false
      console.log(error)
      this.error = error
    },

    /**
     * Handle successful login
     * @param response Response data
     * @returns {Promise<void>}
     */
    async processLogin (response) {
      this.loggingIn = true
      // Store access token
      localStorage.setItem('access_token', response.access_token)

      // Load user data
      APIClient.client.get('/auth/info')
        .then((response) => {
          // Set user
          this.$store.commit('user', User.fromAPI(response.data.user))

          // Store user
          localStorage.setItem('user', JSON.stringify(response.data.user))

          // Load available coupons
          CouponRepository.getCoupons()

          // Set online
          this.$store.commit('setOffline', false)
        })
        .catch((error) => {
          this.loggingIn = false
          this.error = error
        })
        // Push to coupon index
        .finally(() => {
          // Push to coupons
          this.$router.push({ name: 'coupons.index' })
        })
    },

    async handleSubmit (e) {
      this.submitted = true
      const { email, password, name, newsletter } = this
      if (this.isValidEmail() && name && newsletter) {
        const postData = {
          email: email,
          name: name,
          password: password,
          newsletter: this.newsletter
        }
        // Post to auth controller
        APIClient.client.post('/auth/signUp', postData)
          .then(async (response) => {
            if (response.status === 200) {
              await this.processLogin(response.data)
            } else {
              await this.showLoginError(response.data)
            }
            this.submitted = false
          })
          .catch((error) => {
            if (error.message.indexOf(500) !== -1) {
              this.showLoginError('E-mail allerede registreret. Log venligst ind.')
            } else {
              this.showLoginError(error)
            }
            this.submitted = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  .login {
    background-image: url('../assets/background-login.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .form-group {
    input {
      width: 100%;
      height: 44px;
      background: #e0dede;
      justify-content: center;
      display: flex;
      margin: 20px auto;
      padding: 10px;
      border: none;
      outline: none;
      border-radius: 5px;
    }

    button {
      margin: 10px auto;
      justify-content: center;
      display: block;
      color: #fff;
      background: #eea19e !important;
      font-size: 1em;
      font-weight: bold;
      margin-top: 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      transition: .2s ease-in;
      cursor: pointer;
      padding: 10px 25px;
    }

    button:hover {
      background: #E0967B !important;
    }

    .checkbox-container {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      padding-left: 40px;
      margin-bottom: 10px;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .mark {
          background: #eea19e;
        }

        &:checked ~ .mark:after {
          display: block;
        }
      }

      .mark {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background: #eee;
        border-radius: 3px;

        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 16px;
          border: solid white;
          border-width: 0 3px 3px 0;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      &:hover {
        .mark {
          background: #ccc;
        }

      }
    }

    &.errors {
      .mark {
        border: 1px solid red;
      }
    }
    .invalid-feedback{
      color: red;
    }
  }

  .links-group {
    display: flex;
    width: 100%;
    justify-content: space-between;

    a {
      text-decoration: underline;
    }
  }

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    & .path {
      stroke: hsl(210, 70, 75);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  .text-center {
    text-align: center
  }

}
</style>
