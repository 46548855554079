import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Coupons from '@/views/Coupons.vue'
import CouponFavourites from '@/views/CouponFavourites.vue'
import CouponHistory from '@/views/CouponHistory.vue'
import CouponDetail from '@/views/CouponDetail.vue'
import CouponRedeem from '@/views/RedeemCoupon.vue'
import Stores from '@/views/Stores.vue'
import HowTo from '@/views/HowTo.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'
import CookiePolicy from '@/views/CookiePolicy.vue'
import Main from '@/views/Main.vue'
import Login from '@/views/Login.vue'
import Profile from '@/views/Profile.vue'
import AuthCallback from '@/views/AuthCallback.vue'
import Register from '@/views/Register.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/index.html',
    redirect: {
      name: 'main'
    }
  },
  {
    path: '/login',
    name: 'auth.login',
    component: Login
  },
  {
    path: '/register',
    name: 'auth.register',
    component: Register
  },
  {
    path: '/forgot-password',
    name: 'auth.forgot',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'auth.reset',
    component: ResetPassword
  },
  {
    path: '/profile',
    name: 'auth.profile',
    component: Profile,
    meta: {
      transitionName: 'fade'
    }
  },
  {
    path: '/coupons',
    name: 'coupons.index',
    component: Coupons,
    meta: {
      transitionName: 'fade'
    }
  },
  {
    path: '/coupons/favourites',
    name: 'coupons.favourites',
    component: CouponFavourites,
    meta: {
      transitionName: 'fade'
    }
  },
  {
    path: '/coupons/history',
    name: 'coupons.history',
    component: CouponHistory,
    meta: {
      transitionName: 'fade'
    }
  },
  {
    path: '/coupons/:id',
    name: 'coupons.show',
    component: CouponDetail,
    props: true,
    meta: {
      transitionName: 'slide'
    }
  },
  {
    path: '/auth/:provider',
    name: 'auth.callback',
    props: true,
    component: AuthCallback
  },
  {
    path: '/stores',
    name: 'stores',
    component: Stores,
    meta: {
      transitionName: 'slide'
    }
  },
  {
    path: '/how-to',
    name: 'how-to',
    component: HowTo,
    meta: {
      transitionName: 'slide'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      transitionName: 'slide'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      transitionName: 'slide'
    }
  },
  {
    path: '/cookie-policy',
    name: 'cookie',
    component: CookiePolicy,
    meta: {
      transitionName: 'slide'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
