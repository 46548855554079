<template>
  <div class="h-full bg-green login">
    <div>
      <div class="container mx-auto py-14">
        <img src="../assets/logo-white.svg" class="w-1/2 mx-auto"/>
      </div>
    </div>
    <div class="bg-white h-full rounded-t-3xl">
      <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
        <div v-if="error" class="text-center text-black">
          {{ error }}
        </div>
        <div class="text-center">
          Få ny adgangskode
        </div>
        <div v-if="loggingIn">
          <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
        </div>
        <div class="px-4 py-4 flex flex-col" v-if="loggingIn === false">
          <div>
            <form @submit.prevent="handleSubmit">
              <div class="form-group">
                <input type="password" v-model="password" name="password" class="form-control" placeholder="Adgangskode"
                       :class="{'is-invalid': submitted && !email }"/>
                <div v-show="submitted && (!password || !this.isValidPassword)" class="invalid-feedback">Adgangskoden skal indeholde mindst otte tegn</div>
              </div>
              <div class="form-group">
                <input type="password" v-model="confirmPassword" name="confirmPassword" class="form-control"
                       placeholder="Bekræft kodeord"
                       :class="{'is-invalid': submitted && !confirmPassword }"/>
                <div v-show="submitted && (!confirmPassword || !this.isValidConfirmPassword)" class="invalid-feedback">Bekræft adgangskoden stemmer ikke overens</div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary" :disabled="loggingIn">Indsend</button>
                <img v-show="loggingIn"/>
                <div class="links-group">
                  <router-link to="/login" class="btn btn-link">Tilbage til login</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <slide-navigation-item @tapped="selectSlideNavItem('cookie')">
            Cookie- og privatlivspolitik
          </slide-navigation-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SlideNavigationItem from '@/components/SlideNavigationItem'
import { Providers, Facebook, Google } from 'universal-social-auth'
import { APIClient } from '@/data/APIClient'
import { User } from '@/data/models/User'
import { CouponRepository } from '@/data/repositories/CouponRepository'

export default {
  name: 'ForgotPassword',
  metaInfo: {
    title: 'Glemt kodeord'
  },
  components: {
    SlideNavigationItem
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      responseData: {},
      hash: '',
      data: {},
      error: null,
      loggingIn: false,
      password: '',
      confirmPassword: '',
      email: this.$route.query.email,
      key: this.$route.query.key,
      submitted: false
    }
  },
  methods: {

    selectSlideNavItem (item) {
      this.$store.commit('setShowMenu', false)
      this.$router.push({ name: item })
    },
    isValidPassword () {
      return this.password.length < 8
    },
    isValidConfirmPassword () {
      return this.confirmPassword === this.password
    },

    /**
     * @param error{string|object} Response data
     */
    async showError (error) {
      this.loggingIn = false
      console.log(error)
      this.error = error
    },
    async showSuccess (message) {
      this.loggingIn = false
      this.error = message
    },
    async handleSubmit (e) {
      this.submitted = true
      const { password, confirmPassword, email, key } = this
      if (password && confirmPassword && password === confirmPassword) {
        const postData = {
          password: this.password,
          key: key,
          email: email
        }
        // Post to auth controller
        APIClient.client.post('/auth/resetPassword', postData)
          .then(async (response) => {
            await this.showSuccess('Adgangskoden er nulstillet')
            this.password = ''
            this.confirmPassword = ''
            this.submitted = false
          })
          .catch((error) => {
            this.showError(error)
            this.submitted = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  .login {
  //  background-image: url('../assets/background-login.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }

  .form-group {
    input {
      width: 100%;
      height: 44px;
      background: #e0dede;
      justify-content: center;
      display: flex;
      margin: 20px auto;
      padding: 10px;
      border: none;
      outline: none;
      border-radius: 5px;
    }

    button {
      margin: 10px auto;
      justify-content: center;
      display: block;
      color: #fff;
      background: #eea19e !important;
      font-size: 1em;
      font-weight: bold;
      margin-top: 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      transition: .2s ease-in;
      cursor: pointer;
      padding: 10px 25px;
    }

    button:hover {
      background: #E0967B !important
    }
  }

  .links-group {
    display: flex;
    width: 100%;
    justify-content: space-between;

    a {
      text-decoration: underline;
    }
  }

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;

    & .path {
      stroke: hsl(210, 70, 75);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }

  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  .text-center {
    text-align: center
  }

}
</style>
