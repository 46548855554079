<template>
  <a href="#" @click.prevent="$emit('trigger')" class="text-green">
    Tilbage
  </a>
</template>

<script>
export default {
  name: 'BackNavigationItem'
}
</script>

<style scoped>

</style>
