<template>
  <div class="p-5">
    <router-link :to="{ name: 'login' }" class="block mb-2 text-blue-500">login</router-link>
    <router-link :to="{ name: 'coupons.index' }" class="block mb-2 text-blue-500">coupons.index</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Main',
  components: {
  },
  mounted () {
    if (localStorage.getItem('device_token') && localStorage.getItem('access_token')) {
      this.$router.push({ name: 'coupons.index' })
    } else {
      this.$router.push({ name: 'auth.login' })
    }
  }
}
</script>
