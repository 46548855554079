<template>
  <div>
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display font-bold uppercase text-green text-center">Sådan gør du</h1>
      </div>
      <div class="mt-8">
        <p>
          Du kan indløse dine VIP-tilbud ved at fremvise den elektroniske kupon i de enkelte butikker og swipe i samarbejde med butikkens medarbejdere. Kun én kupon pr. kunde.</p>
          <ul style="list-style: disc; padding-left: 16px; margin-top: 15px;">
         <li>VIP-tilbud kan ikke kombineres med andre rabatter og tilbud.</li>
         <li>VIP-tilbud gælder fra 5.-9. november.</li>
         <li>VIP-tilbud gælder KUN i Tarup Center.</li>
         <li>Kan ikke benyttes ved køb af gavekort.</li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { StoreRepostiory } from '@/data/repositories/StoreRepostiory'

export default {
  name: 'HowTo',
  metaInfo: {
    title: 'Sådan gør du'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar
  }
}
</script>
