import { APIClient } from '@/data/APIClient'
import store from '@/store'
import { Store } from '@/data/models/Store'

export class StoreRepostiory {
  static getStores () {
    return APIClient.client.get('/stores/index', {})
      .then((response) => {
        // Extract coupons from response
        const stores = response.data.map((entry: Record<string, any>) => Store.fromAPI(entry))

        // Commit to store
        store.commit('stores', stores)
        this.setCachedStores(response.data)
      })
  }

  static setCachedStores (stores: Record<string, unknown>) {
    localStorage.setItem('stores', JSON.stringify(stores))
  }

  static getCachedStores () {
    const stores = JSON.parse(<string>localStorage.getItem('stores'))
      .map((entry: Record<string, any>) => Store.fromAPI(entry))

    store.commit('stores', stores)
  }
}
