<template>
  <header class="bg-white top-0 w-full z-10" :class="{
    'fixed': position === 'fixed',
    'absolute': position === 'absolute',
    'pt-navbar': !modal
  }">
    <nav class="flex justify-between items-center w-full text-white p-4">
      <div class="flex flex-1 flex-shrink ">
        <slot name="left" class="text-blue-500"/>
      </div>
      <div class="flex flex-grow items-center">
        <img src="../assets/logo.svg" class="w-1/2 mx-auto h-3.5" v-if="showTitle" />
      </div>
      <div class="flex flex-1 flex-shrink justify-end">
        <share-navigation-item v-if="showShareButton" />
      </div>
    </nav>
  </header>
</template>

<script>
import ShareNavigationItem from '@/components/ShareNavigationItem'

export default {
  name: 'NavBar',
  components: {
    ShareNavigationItem
  },
  props: {
    position: {
      type: String,
      default: 'fixed'
    },
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    showShareButton: {
      type: Boolean,
      required: false,
      default: true
    },
    modal: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
