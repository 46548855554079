<template>
  <div>
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display font-bold uppercase text-green text-center">Butikker</h1>
      </div>
      <div v-if="this.$store.getters.ready" class="mt-8">
        <div v-if="this.stores.length > 0">
          <div class="grid grid-cols-2 gap-4">
            <div
              v-for="(store) in stores"
              :key="store.id"
              class="bg-white shadow rounded-2xl h-28 p-4">
              <img :src="store.logo" class="logo" />
            </div>
          </div>
        </div>
        <div v-else>
          Der blev ikke fundet nogle butikker
        </div>
      </div>
      <div v-else>
        Indlæser..
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { StoreRepostiory } from '@/data/repositories/StoreRepostiory'

export default {
  name: 'Stores',
  metaInfo: {
    title: 'Butikker'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar
  },
  data () {
    return {
      loading: false,
      error: null
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    $route: 'fetchData',
    stores: {
      handler (val) {
        if (val.length !== 0) {
          this.loading = false
        }
      },
      immediate: true
    }
  },
  computed: {
    stores () {
      return this.$store.state.stores ?? []
    }
  },
  methods: {
    fetchData () {
      if (this.stores.length === 0) {
        // Handle offline state
        if (this.$store.state.offline) {
          StoreRepostiory.getCachedStores()
          this.loading = false
          return
        }

        StoreRepostiory.getStores()
          .catch((error) => {
            this.error = error
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
</style>
