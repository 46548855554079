<template>
  <div class="h-full w-full overflow-y-auto" v-if="ready">
    <slot />
  </div>
  <div class="h-full w-full overflow-y-auto flex justify-center items-center" v-else>
    Indlæser..
  </div>
</template>

<script>
export default {
  name: 'PageContainer',
  computed: {
    ready () {
      if (['auth.login', 'auth.callback', 'main'].includes(this.$router.currentRoute.name)) {
        return true
      }

      return this.$store.getters.ready
    }
  }
}
</script>

<style scoped>

</style>
