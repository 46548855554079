const requiresAuthMixin = {
  beforeRouteEnter (to: any, from: any, next: any) {
    // Redirect to login if requirements for authentication is not met
    if (!localStorage.getItem('device_token') || !localStorage.getItem('access_token')) {
      next({ name: 'auth.login' })
    }

    next()
  }
}

export default requiresAuthMixin
