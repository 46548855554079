import UniversalSocialauth from 'universal-social-auth'
import axios from 'axios'

const options = {
  providers: {
    google: {
      clientId: '573537610887-f6hcqkf9nqkisv1nfb0sm8riop0notb4.apps.googleusercontent.com',
      redirectUri: window.location.origin + '/auth/google'
    },
    facebook: {
      clientId: '392398112941735', // 339757347530091
      redirectUri: window.location.origin + '/auth/facebook',
      popupOptions: {
        width: 580,
        height: 480
      }
    }
  }
}

const Oauth = new UniversalSocialauth(axios, options)

export default Oauth
