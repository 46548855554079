export class Store {
  id: number;
  title: string;
  logo: string;

  constructor (id: number, title: string, logo: string) {
    this.id = id
    this.title = title
    this.logo = logo
  }

  static fromAPI (entry: Record<string, any>): Store {
    return new Store(
      entry.id,
      entry.title,
      entry.logo
    )
  }
}
