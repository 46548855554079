<template>
  <a href="#"
     class="flex flex-1 flex-grow items-center justify-center p-2"
     :class="{
      'text-blue': displayMode === 'regular',
      'text-blue': displayMode === 'regular' && active,
      'flex-3 text-white bg-light-orange rounded-lg px-6': displayMode === 'focus',
      'text-white': displayMode === 'focus' && active
     }"
     @click.prevent="$emit('tapped')">
    <div class="text-center" :class="{
      'flex items-center': displayMode === 'focus'
    }">
      <span class="block h-8 flex justify-center items-center" :class="{
        'mr-2': displayMode === 'focus'
      }">
        <slot />
      </span>
      <span class="block leading-none" :class="{
        'text-xs font-light': displayMode === 'regular',
        'text-base font-medium': displayMode === 'focus'
      }">{{ title }}</span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'TabBarItem',
  props: {
    displayMode: {
      type: String,
      required: false,
      default: 'regular'
    },
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
