<template>
  <a href="#"
     class="flex gap-4 my-4 font-light"
     @click.prevent="$emit('tapped')">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'SlideNavigationItem'
}
</script>

<style scoped>

</style>
