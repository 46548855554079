export class User {
  id: number;
  name: string;
  email: string;
  image: string;
  createdAt: string;

  constructor (id: number, name: string, email: string, image: string, createdAt: string) {
    this.id = id
    this.name = name
    this.email = email
    this.image = image
    this.createdAt = createdAt
  }

  static fromAPI (entry: Record<string, any>): User {
    return new User(
      entry.id,
      entry.name,
      entry.email,
      entry.image,
      entry.created_at
    )
  }
}
