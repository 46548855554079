<template>
  <div class="relative">
    <div class="outer" ref="outer" :class="{ 'unlocked': unlocked }">
      <div class="lock flex items-center justify-center" ref="lock" @mousedown="dragStart" @touchstart="dragStart">
        <slot name="lock" />
      </div>
    </div>
    <div class="message absolute text-white font-bold text-center text-xl" ref="message">
      <template v-if="unlocked">Indløst</template>
      <template v-else>Indløs rabatkupon</template>
    </div>
  </div>
</template>

<script>
let lockRect = null
let dragProps = null

export default {
  name: 'SlideToUnlock',
  data () {
    return {
      unlocked: false
    }
  },
  computed: {
    outerRect () {
      return this.$refs.outer.getBoundingClientRect()
    },
    isTouch () {
      return 'ontouchstart' in document.documentElement
    }
  },
  methods: {
    dragStart (e) {
      if (this.unlocked) return
      lockRect = this.$refs.lock.getBoundingClientRect()
      const x = this.getX(e)
      dragProps = {
        start: lockRect.left - this.outerRect.left,
        mouseStart: x,
        newX: 0
      }
      this.$refs.lock.classList.add('dragging')
      document.addEventListener('mousemove', this.dragLock, false)
      document.addEventListener('touchmove', this.dragLock, false)
      document.addEventListener('mouseup', this.dragStop)
      document.addEventListener('touchend', this.dragStop)
    },
    dragStop (reset) {
      this.$refs.lock.classList.remove('dragging')
      if (reset !== false) {
        this.$refs.lock.style.left = '0px'
        this.$refs.message.style.opacity = '100%'
        this.unlocked = false
      }
      document.removeEventListener('mousemove', this.dragLock, false)
      document.removeEventListener('touchmove', this.dragLock, false)
      document.removeEventListener('mouseup', this.dragStop)
      document.removeEventListener('touchend', this.dragStop)
    },
    unlock () {
      this.dragStop(false)
      this.unlocked = true
      this.$emit('completed')
    },
    dragLock (e) {
      e.preventDefault()
      const posX = this.getX(e)
      const mouseDiff = posX - dragProps.mouseStart
      const maxX = this.outerRect.width - lockRect.width - 6
      let newX = dragProps.start + mouseDiff
      newX = this.clamp(newX, 0, maxX)
      this.$refs.lock.style.left = newX + 'px'

      const percentage = posX / maxX * 100
      if (percentage < 50) {
        this.$refs.message.style.opacity = (100 - (percentage * 3)) + '%'
      } else {
        this.$refs.message.style.opacity = 0 + '%'
      }

      // Unlock
      if (newX >= maxX) {
        this.unlock()
      }
    },
    clamp (value, min, max) {
      return Math.min(Math.max(value, min), max)
    },
    getX (event) {
      if (this.isTouch === true) {
        return event.touches[0].pageX
      } else {
        return event.clientX
      }
    }
  }
}
</script>

<style scoped lang="scss">
.outer {
  background: #eea19e;
  border: 3px solid #eea19e;
  display: inline-block;
  width: 100%;
  position: relative;
  user-select: none;
  border-radius: 10px;

  .lock {
    width: 62px;
    height: 62px;
    background: #FFF;
    cursor: pointer;
    position: relative;
    left: 0;
    transition: background 0.2s ease-out, left 0.3s ease-out;
    border-radius: inherit;

    &.dragging {
      transition: none;
    }
  }

  &.unlocked {
    opacity: 0.5;

    .lock {
      cursor: none;
    }
  }
}

.message {
  top: 0;
  height: 68px;
  width: 100%;
  line-height: 68px;
  pointer-events: none;
}
</style>
