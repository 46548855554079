import { APIClient } from '@/data/APIClient'
import store from '@/store'
import { Coupon } from '@/data/models/Coupon'
import { dataLayer } from '@/data/dataLayer'

export class CouponAction {
  /**
   * Redeem coupon
   * @param coupon
   */
  static redeem (coupon: Coupon) {
    dataLayer.push({
      event: 'redeem-coupon',
      coupon: coupon
    })

    return APIClient.client.post(`/coupons/${coupon.id}/redeem`, {})
  }

  /**
   * Favourite coupon
   * @param coupon
   */
  static favourite (coupon: Coupon) {
    dataLayer.push({
      event: 'favourite-coupon',
      coupon: coupon
    })

    return APIClient.client.post(`/coupons/${coupon.id}/favourite`, {})
  }
}
