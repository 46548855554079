<template>
  <a href="#" class="text-blue" @click.prevent="$emit('trigger'); share()">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3156 16.6578L8.6938 13.3469M8.68439 10.6578L15.3125 7.34377M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18ZM21 6C21 7.65685 19.6569 9 18 9C16.3431 9 15 7.65685 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6ZM9 12C9 13.6569 7.65685 15 6 15C4.34315 15 3 13.6569 3 12C3 10.3431 4.34315 9 6 9C7.65685 9 9 10.3431 9 12Z" stroke="currentColor" stroke-width="1.5"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'ShareNavigationItem',
  data () {
    return {
      url: 'https://tarupcenter.dk',
      title: 'Tarup Center Kuponer'
    }
  },
  methods: {
    share () {
      if (navigator.share) {
        navigator
          .share({
            title: this.title,
            url: this.url
          })
          .then(() => {
            // TODO: Log event
          })
          .catch(console.error)
      } else {

      }
    },
    showFallbackShareDialog () {
      console.log('fallback')
    }
  }
}
</script>

<style scoped>

</style>
