import axios from 'axios'
import uuid from 'uuid-random'

export class APIClient {
  /**
   * Get API base path
   * @return {string}
   */
  static get base () {
    if (process.env.NODE_ENV === 'production') {
      return 'https://kupon.tarupcenter.dk/backend/wp-json/tc-coupons/v1'
    }
    if (process.env.NODE_ENV === 'dev') {
      return 'https://tarupcenter.indexed.dev/backend/wp-json/tc-coupons/v1'
    }

    return 'http://localhost:8081/tarup-koupon/wp-json/tc-coupons/v1'
  }

  /**
   * Get Axios instance
   * @return {AxiosInstance}
   */
  static get client () {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Device-Token': this.deviceToken,
      'X-Access-Token': localStorage.getItem('access_token') ?? undefined
    }

    return axios.create({
      baseURL: this.base,
      withCredentials: false,
      headers: headers
    })
  }

  static get deviceToken () {
    // Set token
    if (!localStorage.getItem('device_token')) {
      localStorage.setItem('device_token', uuid())
    }

    return localStorage.getItem('device_token')
  }
}
