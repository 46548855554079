<template>
  <a href="#" @click.prevent="$emit('trigger')" class="text-green">
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.0302 17.0303C28.3231 16.7374 28.3231 16.2626 28.0302 15.9697C27.7373 15.6768 27.2624 15.6768 26.9696 15.9697L28.0302 17.0303ZM15.9696 26.9697C15.6767 27.2626 15.6767 27.7374 15.9696 28.0303C16.2625 28.3232 16.7373 28.3232 17.0302 28.0303L15.9696 26.9697ZM17.0304 15.9697C16.7376 15.6768 16.2627 15.6768 15.9698 15.9697C15.6769 16.2626 15.6769 16.7374 15.9698 17.0303L17.0304 15.9697ZM26.9698 28.0303C27.2627 28.3232 27.7375 28.3232 28.0304 28.0303C28.3233 27.7374 28.3233 27.2626 28.0304 26.9697L26.9698 28.0303ZM26.9696 15.9697L15.9696 26.9697L17.0302 28.0303L28.0302 17.0303L26.9696 15.9697ZM15.9698 17.0303L26.9698 28.0303L28.0304 26.9697L17.0304 15.9697L15.9698 17.0303ZM37.75 22C37.75 30.6985 30.6985 37.75 22 37.75V39.25C31.5269 39.25 39.25 31.5269 39.25 22H37.75ZM22 37.75C13.3015 37.75 6.25 30.6985 6.25 22H4.75C4.75 31.5269 12.4731 39.25 22 39.25V37.75ZM6.25 22C6.25 13.3015 13.3015 6.25 22 6.25V4.75C12.4731 4.75 4.75 12.4731 4.75 22H6.25ZM22 6.25C30.6985 6.25 37.75 13.3015 37.75 22H39.25C39.25 12.4731 31.5269 4.75 22 4.75V6.25Z" fill="#054865"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'CloseNavigationItem'
}
</script>

<style scoped>

</style>
