import Vue from 'vue'

const popFromStackMixin = Vue.extend({
  methods: {
    popView (): void {
      this.$router.back()
    }
  }
})

export default popFromStackMixin
