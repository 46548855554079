import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './assets/tailwind.css'
import axios from 'axios'
import UniversalSocialauth from 'universal-social-auth'
import Oauth from '@/oauth'
import VueMeta from 'vue-meta'

// Disable production warning
Vue.config.productionTip = false

// Configure shared instances
Vue.prototype.$axios = axios
Vue.prototype.$Oauth = Oauth

Vue.use(VueMeta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Trigger add to home screen
document.addEventListener('DOMContentLoaded', function () {
  window.addToHomescreen({
    appID: 'dk.tarupcenter.coupons',
    debug: true,
    message: {
      ios: 'Føj Tarup Center til din hjemmeskærm: Tryk %icon og derefter <strong>Føj til hjemmeskærm</strong>.',
      android: 'For at tilføje Tarup Center til hjemmeskærm, åbn browser egenskaber menuen og tryk på <strong>Føj til hjemmeskærm</strong>. <small>Denne menu kan tilgås ved at trykke på menuknappen, hvis din enhed har en, eller ved at trykke på det øverste højre menu ikon %icon.</small>'
    },
    lifespan: 30,
    displayPace: 0,
    detectHomescreen: true
  })
})
