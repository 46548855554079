var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"flex flex-1 flex-grow items-center justify-center p-2",class:{
    'text-blue': _vm.displayMode === 'regular',
    'text-blue': _vm.displayMode === 'regular' && _vm.active,
    'flex-3 text-white bg-light-orange rounded-lg px-6': _vm.displayMode === 'focus',
    'text-white': _vm.displayMode === 'focus' && _vm.active
   },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('tapped')}}},[_c('div',{staticClass:"text-center",class:{
    'flex items-center': _vm.displayMode === 'focus'
  }},[_c('span',{staticClass:"block h-8 flex justify-center items-center",class:{
      'mr-2': _vm.displayMode === 'focus'
    }},[_vm._t("default")],2),_c('span',{staticClass:"block leading-none",class:{
      'text-xs font-light': _vm.displayMode === 'regular',
      'text-base font-medium': _vm.displayMode === 'focus'
    }},[_vm._v(_vm._s(_vm.title))])])])}
var staticRenderFns = []

export { render, staticRenderFns }