<template>
  <div>
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display font-bold uppercase text-green text-center">Kontakt</h1>
      </div>
      <div class="mt-8">
        <p>Tarup Center . Information tlf. <a href="tel:96 13 21 09">96 13 21 09</a> <br/> <a href="mailto:centerkontor@tarupcenter.dk">centerkontor@tarupcenter.dk</a><br/> <a href="https://www.tarupcenter.dk">www.tarupcenter.dk</a></p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'
import requiresAuthMixin from '../mixins/requiresAuth'
import { StoreRepostiory } from '@/data/repositories/StoreRepostiory'

export default {
  name: 'Contact',
  metaInfo: {
    title: 'Kontakt'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    NavBar
  }
}
</script>
