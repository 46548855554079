import { Store } from '@/data/models/Store'

export class Coupon {
  id: number;
  title: string;
  subtitle: string;
  store: Store;
  description: string;
  image: string;
  heroImage: string;
  redeemed = false;
  redeemedAt: string
  favourite = false;
  validity: string;
  newsletterOnly: string;

  constructor (id: number, title: string, subtitle: string, description: string, store: Store, image: string, heroImage: string, redeemed: boolean, redeemedAt: string, favourite: boolean, validity: string, newsletterOnly: string) {
    this.id = id
    this.title = title
    this.subtitle = subtitle
    this.store = store
    this.description = description
    this.image = image
    this.heroImage = heroImage
    this.redeemed = redeemed
    this.redeemedAt = redeemedAt
    this.favourite = favourite
    this.validity = validity
    this.newsletterOnly = newsletterOnly
  }

  static fromAPI (entry: Record<string, any>): Coupon {
    return new Coupon(
      entry.id,
      entry.title,
      entry.subtitle,
      entry.description,
      Store.fromAPI(entry.store),
      entry.image,
      entry.hero_image,
      entry.redeemed,
      entry.redeemed_at,
      entry.favourite,
      entry.validity,
      entry.newsletter_only
    )
  }
}
