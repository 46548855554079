<template>
  <div>
    <nav-bar>
      <template slot="left">
        <burger-navigation-item />
      </template>
    </nav-bar>
    <div class="container max-w-sm mx-auto pt-content pb-20 px-4">
      <div class="mb-3">
        <h1 class="text-3xl font-display font-bold uppercase text-green text-center">Din profil</h1>
      </div>
      <div v-if="this.$store.getters.ready" class="flex flex-col items-center pt-10">
        <user-info />

        <a href="#" class="rounded-md border border-blue text-blue py-2.5 px-4 inline-flex items-center mt-4 w-fit-content btn-log-out" @click.prevent="signOut">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 4L18 4C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H14M3 12L15 12M3 12L7 8M3 12L7 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="ml-4">Log ud</span>
        </a>
      </div>
      <div v-else>
        Indlæser..
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import requiresAuthMixin from '@/mixins/requiresAuth'
import UserInfo from '@/components/UserInfo'
import BurgerNavigationItem from '@/components/BurgerNavigationItem'

export default {
  name: 'Profile',
  metaInfo: {
    title: 'Din profil'
  },
  mixins: [
    requiresAuthMixin
  ],
  components: {
    BurgerNavigationItem,
    UserInfo,
    NavBar
  },
  methods: {
    signOut () {
      // Remove access token
      localStorage.removeItem('access_token')

      this.$router.push({ name: 'auth.login' })
    }
  }
}
</script>
