import { APIClient } from '@/data/APIClient'
import store from '@/store'
import { Coupon } from '@/data/models/Coupon'

export class CouponRepository {
  static getCoupons () {
    return APIClient.client.get('/coupons/index', {})
      .then((response) => {
        // Extract coupons from response
        const coupons = response.data.map((entry: Record<string, any>) => Coupon.fromAPI(entry))

        // Commit to store
        store.commit('coupons', coupons)
        this.setCachedCoupons(response.data)
      })
  }

  static setCachedCoupons (coupons: Record<string, unknown>) {
    localStorage.setItem('coupons', JSON.stringify(coupons))
  }

  static getCachedCoupons () {
    if (localStorage.getItem('coupons')) {
      const coupons = JSON.parse(<string>localStorage.getItem('coupons'))
        .map((entry: Record<string, any>) => Coupon.fromAPI(entry))

      store.commit('coupons', coupons)
    }
  }
}
