<template>
  <div>
    <div class="group w-full flex mx-auto mb-4">
      <div class="bg-white w-full transform transition-all duration-300 scale-100 relative shadow-coupon rounded-2xl">
        <div class="flex w-full p-5" @click.prevent="$emit('show-coupon')">
            <div class="block h-28 w-28 rounded-lg overflow-hidden bg-cover" :style="{
              backgroundImage: `url(${coupon.image})`,
              backgroundSize: 'cover'
            }"/>
          <div class="pl-3 w-3/5 flex justify-between flex-col">
            <div>
              <h3 class="text-lg text-black uppercase font-extrabold font-display">{{ coupon.title }}</h3>
              <p class="text-sms text-black font-normal leading-tight" v-if="coupon.subtitle">{{ coupon.subtitle }}</p>
            </div>
            <div class="flex w-full items-center text-xs text-gray-500 font-medium">
              <div v-if="coupon.store && coupon.store.logo">
                <img :src="coupon.store.logo" crossorigin="anonymous" class="logo" />
              </div>
            </div>
          </div>
        </div>
        <div class=" absolute top-0 w-full h-full pointer-events-none" v-if="!coupon.redeemed">
          <div class="p-6 h-full flex flex-col justify-end">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!coupon.favourite" class="cursor-pointer pointer-events-auto relative z-30" @click.prevent="favourite()">
              <path d="M14 19.8333L7 23.3333L8.75 16.3333L3.5 10.5L11.0833 9.91667L14 3.5L16.9167 9.91667L24.5 10.5L19.25 16.3333L21 23.3333L14 19.8333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="coupon.favourite" class="cursor-pointer pointer-events-auto relative z-30" @click.prevent="favourite()">
              <path d="M14 19.8333L7 23.3333L8.75 16.3333L3.5 10.5L11.0833 9.91667L14 3.5L16.9167 9.91667L24.5 10.5L19.25 16.3333L21 23.3333L14 19.8333Z" fill="#D32610" stroke="#D32610" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="bg-blue bg-opacity-85 absolute top-0 w-full h-full text-white rounded-2xl" v-if="coupon.redeemed">
          <div class="p-5 h-full flex flex-col justify-end">
            <span class="font-bold">Indløst</span>
            <span>{{ coupon.redeemedAt }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Coupon } from '../data/models/Coupon'
import { CouponAction } from '@/data/CouponAction'
import { CouponRepository } from '@/data/repositories/CouponRepository'

export default {
  name: 'Coupon',
  props: {
    coupon: {
      type: Coupon,
      required: true
    }
  },
  methods: {
    favourite () {
      CouponAction
        .favourite(this.coupon)
        .finally(() => {
          CouponRepository.getCoupons()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 30px;
  object-fit: contain;
  object-position: left center;
}
</style>
