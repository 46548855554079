<template>
  <div class="h-full w-full bg-blue pt-modal absolute top-0 z-50">
    <div class="h-full w-full rounded-t-3xl bg-white relative pb-modal px-4 overflow-x-hidden">
      <!-- Resource found -->
      <div v-if="coupon && !coupon.redeemed" class="flex flex-col justify-between h-full">
        <div>
          <nav-bar :show-title="false" :show-share-button="false" :modal="true" position="absolute" class="rounded-t-2xl">
            <template slot="left">
              <close-navigation-item @trigger="$emit('close-popup', false)" />
            </template>
          </nav-bar>

          <div class="pt-20">
            <div class="px-4 bg-white rounded-3xl shadow-offer">
              <user-info :show-email="false" :show-member-since="false" avatar-size="medium" />

              <!-- Action arrow -->
              <div class="flex justify-center items-center py-7">
                <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30.5192 22.2102C29.8779 21.5637 28.8392 21.5637 28.1979 22.2102L17.1359 33.3787V1.65254C17.1359 0.739678 16.4021 0 15.4965 0C14.591 0 13.8572 0.739678 13.8572 1.65254V33.378L2.80173 22.2102C2.16043 21.5637 1.12177 21.5637 0.48047 22.2102C-0.160828 22.8566 -0.160828 23.9037 0.48047 24.5502L14.3359 38.5175C14.4625 38.6444 14.6113 38.7422 14.7687 38.8209C14.8008 38.8367 14.8303 38.8572 14.8631 38.8718C14.9169 38.8942 14.9746 38.9075 15.0303 38.924C15.0775 38.9379 15.1221 38.957 15.1713 38.9669C15.2775 38.9888 15.3864 39 15.4965 39C15.6067 39 15.7156 38.9888 15.8218 38.9669C15.8703 38.957 15.9156 38.9379 15.9628 38.924C16.0192 38.9075 16.0762 38.8942 16.13 38.8718C16.1634 38.8579 16.1923 38.8374 16.2244 38.8209C16.3818 38.7422 16.53 38.6444 16.6572 38.5175L30.5192 24.5502C31.1605 23.9037 31.1605 22.8566 30.5192 22.2102Z" fill="#054865" fill-opacity="0.18"/>
                </svg>
              </div>

              <div class="container max-w-sm mx-auto pb-6">
                <div>
                  <h2 class="text-center font-display font-extrabold uppercase text-xxl text-dark">{{ coupon.title }}</h2>
                  <div v-html="coupon.subtitle" class="text-center mt-2"/>
                  <img :src="coupon.store.logo" v-if="coupon.store && coupon.store.logo" class="logo mx-auto mt-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4">
          <div v-if="error" class="text-black">
            {{ error }}
          </div>
          <div v-else>
            <div class="flex items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7H12.75ZM11.25 14C11.25 14.4142 11.5858 14.75 12 14.75C12.4142 14.75 12.75 14.4142 12.75 14H11.25ZM12.75 16.99C12.75 16.5758 12.4142 16.24 12 16.24C11.5858 16.24 11.25 16.5758 11.25 16.99H12.75ZM11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17H11.25ZM11.25 7V14H12.75V7H11.25ZM11.25 16.99V17H12.75V16.99H11.25ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z" fill="black"/>
              </svg>
              <span class="ml-2 text-black">Kuponen skal indløses ved kassen sammen med en ekspedient.</span>
            </div>
          </div>

          <slide-to-unlock @completed="redeemCoupon()" ref="slider" class="mt-4">
            <template slot="lock">
              <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-green" v-if="!processing">
                <path d="M1 17L9 9L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <svg class="animate-spin w-7 h-7 text-green" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="processing">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </template>
          </slide-to-unlock>
        </div>
      </div>
      <div v-else-if="coupon && coupon.redeemed" class="flex flex-col justify-center items-center h-full relative">
        <nav-bar :show-title="false" :show-share-button="false" :modal="true" position="absolute" class="top-0">
          <template slot="left">
            <close-navigation-item @trigger="$emit('close-popup', true)" />
          </template>
        </nav-bar>
        <div class="mb-4">
          <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M61.1213 29.1213C62.2929 27.9497 62.2929 26.0503 61.1213 24.8787C59.9497 23.7071 58.0503 23.7071 56.8787 24.8787L61.1213 29.1213ZM30.9999 55.0001L28.8786 57.1214C30.0502 58.293 31.9497 58.293 33.1212 57.1214L30.9999 55.0001ZM21.1211 40.8787C19.9495 39.7071 18.05 39.7071 16.8784 40.8787C15.7069 42.0503 15.7069 43.9498 16.8784 45.1213L21.1211 40.8787ZM56.8787 24.8787L28.8786 52.8788L33.1212 57.1214L61.1213 29.1213L56.8787 24.8787ZM16.8784 45.1213L28.8786 57.1214L33.1212 52.8788L21.1211 40.8787L16.8784 45.1213ZM72 39C72 57.2254 57.2254 72 39 72V78C60.5391 78 78 60.5391 78 39H72ZM39 72C20.7746 72 6 57.2254 6 39H0C0 60.5391 17.4609 78 39 78V72ZM6 39C6 20.7746 20.7746 6 39 6V0C17.4609 0 0 17.4609 0 39H6ZM39 6C57.2254 6 72 20.7746 72 39H78C78 17.4609 60.5391 0 39 0V6Z" fill="#ADB30A"/>
          </svg>
        </div>
        <h2 class="font-extrabold text-dark text-xxl font-display text-center uppercase max-w-80vw">Rabatkode indløst</h2>
        <small class="font-light-grey font-light text-smsl">{{ coupon.redeemedAt }}</small>
      </div>

      <!-- Resource not found -->
      <div v-else class="flex h-full w-full justify-center items-center">
        <div>Indholdet blev ikke fundet</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import { Coupon } from '../data/models/Coupon'
import popFromStackMixin from '../mixins/popFromstack'
import SlideToUnlock from '@/components/SlideToUnlock'
import UserInfo from '@/components/UserInfo'
import { CouponAction } from '@/data/CouponAction'
import { CouponRepository } from '@/data/repositories/CouponRepository'
import requiresAuthMixin from '@/mixins/requiresAuth'
import CloseNavigationItem from '@/components/CloseNavigationItem'

export default {
  name: 'RedeemCoupon',
  mixins: [
    popFromStackMixin,
    requiresAuthMixin
  ],
  components: {
    CloseNavigationItem,
    UserInfo,
    SlideToUnlock,
    NavBar
  },
  props: {
    id: {
      required: true
    }
  },
  data () {
    return {
      redeemed: false,
      processing: false,
      error: null
    }
  },
  computed: {
    coupon () {
      return this.$store.getters.getCouponById(parseInt(this.id)) ?? null
    }
  },
  methods: {
    redeemCoupon () {
      this.processing = true

      // Redeem coupon
      CouponAction.redeem(this.coupon)
        .then((response) => {
          this.redeemed = true

          CouponRepository.getCoupons()
        })
        .catch((error) => {
          this.error = error

          // Reset slider
          this.$refs.slider.dragStop(true)
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  width: 130px;
  height: 74px;
  object-fit: contain;
  object-position: left center;
}
</style>
